import {
  BillingRequestResource,
  MandateRequestVerify,
  PaylinkResource,
  PlanResource,
} from "@gocardless/api/dashboard/types";
import { Scheme } from "src/common/scheme";

export enum PaymentVariant {
  InstantBankPay = "InstantBankPay",
  DualFlow = "DualFlow",
  DirectDebitMandate = "DirectDebitMandate",
  DirectDebitOneOff = "DirectDebitOneOff",
  DirectDebitRestrictedMandate = "DirectDebitRestrictedMandate",
  VerifiedMandate = "VerifiedMandate",
  VariableRecurringPayments = "VariableRecurringPayments",
  VariableRecurringPaymentsWithFirstPayment = "VariableRecurringPaymentsWithFirstPayment",
  VariableFirstPaymentWithPlan = "VariableFirstPaymentWithPlan",
}

export const getPaymentVariant = (
  billingRequest: BillingRequestResource,
  paylink?: PaylinkResource | undefined,
  plan?: PlanResource | undefined
): PaymentVariant | null => {
  // VariableFirstPaymentWithPlan
  if (billingRequest.payment_request && billingRequest.mandate_request && plan)
    return PaymentVariant.VariableFirstPaymentWithPlan;

  // IBP
  if (billingRequest.payment_request && !billingRequest.mandate_request)
    return PaymentVariant.InstantBankPay;

  if (!billingRequest.payment_request && billingRequest.mandate_request) {
    // VRP (no initial payment taken)
    if (billingRequest.mandate_request.scheme === Scheme.FasterPayments)
      return PaymentVariant.VariableRecurringPayments;

    // VERIFIED MANDATE
    if (billingRequest.mandate_request.verify) {
      const verify = billingRequest?.mandate_request
        ?.verify as MandateRequestVerify;
      if (
        [
          MandateRequestVerify.WhenAvailable,
          MandateRequestVerify.Always,
        ].includes(verify)
      )
        return PaymentVariant.VerifiedMandate;
    }

    // DD RESTRICTED MANDATE
    if (paylink?.payments_require_approval)
      return PaymentVariant.DirectDebitRestrictedMandate;

    // DD ONE OFF
    if (paylink) return PaymentVariant.DirectDebitOneOff;

    // DD MANDATE
    return PaymentVariant.DirectDebitMandate;
  }

  // VRP OR DUAL FLOW
  if (billingRequest.payment_request && billingRequest.mandate_request) {
    // VRP (initial payment taken)
    if (
      billingRequest.payment_request.scheme === Scheme.FasterPayments &&
      billingRequest.mandate_request.scheme === Scheme.FasterPayments
    )
      return PaymentVariant.VariableRecurringPaymentsWithFirstPayment;

    // DUAL FLOW
    return PaymentVariant.DualFlow;
  }

  return null;
};
